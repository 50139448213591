/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
// import PropTypes from "prop-types"
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, keywords, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						keywords
					}
				}
			}
		`
	);

	let titleAppend;
	if (lang === 'en' && title.length < 15) titleAppend = 'Home Construction Regulatory Authority';
	else if (lang === 'en' && title.length >= 15) titleAppend = 'HCRA';
	else if (lang === 'fr' && title.length < 15)
		titleAppend = "L'Office de réglementation de la construction des logements";
	else titleAppend = 'ORCL';

	const metaDescription = description || site.siteMetadata.description;
	const metaKeywords = keywords ? `${site.siteMetadata.keywords} ${keywords}` : site.siteMetadata.keywords;

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={title}
			titleTemplate={`${title} | ${titleAppend}`}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					property: `og:title`,
					content: title
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `keywords`,
					content: metaKeywords
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author
				},
				{
					name: `twitter:title`,
					content: title
				},
				{
					name: `twitter:description`,
					content: metaDescription
				}
			].concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``
};

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export default SEO;
